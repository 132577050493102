import React from 'react';
import Loadable from 'react-loadable';
import SectionUsage from '../partials/_SectionUsage';
import SectionPlatform from '../partials/_SectionPlatform';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  ComponentWithLabel,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import SectionMicroHelpText from '../partials/_SectionMicroHelpText';
import checkboxPreviewData from '../../../../data/previews/checkbox.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const CheckboxSize = Loadable({
  loader: () => import('./_checkboxSizePartial'),
  loading: () => null,
});

const CheckboxState = Loadable({
  loader: () => import('./_checkboxStatePartial'),
  loading: () => null,
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sizeSelection: 'xsmall',
    };
  }

  handleSizeChange(value) {
    this.setState({
      sizeSelection: value,
    });
  }

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Checkbox"
        designCode
        subnav="components">
        <PageHero
          heroData={pageHeroData}
          tierTwo="Forms"
          tierThree="Checkbox"
        />
        <V5Notice
          componentName="Checkbox"
          storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-checkboxes--docs"
        />
        <PageNavigation
          links={[
            'Size',
            'State',
            'Mobile',
            'Usage',
            'Microcopy',
            'Accessibility',
            'Platform',
          ]}
        />

        <Section title="Size">
          <Paragraph>
            A checkbox can be any of the four available sizes.
          </Paragraph>
          <ComponentPreview
            name="CheckboxSize"
            previewData={checkboxPreviewData.sizes}
            onChange={this.handleSizeChange.bind(this)}>
            <CheckboxSize size={this.state.sizeSelection} />
          </ComponentPreview>
          <Paragraph>
            A form’s density (standard, compact) is dictated by the form
            modifier component. Check{' '}
            <Link href="/components/forms/form-modifier/design">
              {' '}
              those guidelines
            </Link>{' '}
            for more detailed recommendations.
          </Paragraph>
        </Section>

        <Section title="State">
          <Paragraph>
            A checkbox’s state depends on the interaction itself or a submission
            error.
          </Paragraph>
          <ComponentPreview name="CheckboxState">
            <ComponentWithLabel>
              <CheckboxState />
            </ComponentWithLabel>
          </ComponentPreview>
          <Paragraph>
            The actual <em>focus</em> state is sorted for you, so anyone
            navigating via keyboard or screen reader is set. A disabled checkbox
            prevents the user from interacting with a field until certain
            conditions are met. It’s a good way to prevent invalid submissions,
            but should be used sparingly.
          </Paragraph>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Use Uniform checkboxes for both Apple and Android devices.
            Checkboxes should only be used within forms. If there are more than
            six options, display the options in a separate{' '}
            <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/views/tables/">
              table view
            </Link>
            .
          </Paragraph>
          <Paragraph>
            When selecting multiple items to perform an action, use a{' '}
            <Link href="/components/select-mark/design/">select mark</Link> in
            place of the checkbox.
          </Paragraph>
        </Section>
        <SectionUsage>
          <SectionSubhead>Checkbox Buttons</SectionSubhead>
          <Paragraph>
            The “button” or “button-minimal” display type can be used to show
            the checkbox options as a row or column of buttons. These should be
            used sparingly as a way for users to quickly select option(s) and
            only when all of the options are concise - no more than 15
            characters.
          </Paragraph>
          <DontDo
            dontText="include long labels in the checkbox button options."
            doText="use checkbox buttons to toggle or filter data."
            imgFilename="checkbox-button"
          />
        </SectionUsage>
        <Section title="Microcopy">
          <SectionSubhead>Labels</SectionSubhead>
          <Paragraph>
            A label should clearly and concisely describe the field’s purpose
            with no more than a few words.
          </Paragraph>
          <DontDo
            dontText="make the label a complete sentence telling the user what to do."
            doText="name exactly what belongs in that field."
            imgFilename="checkbox-label"
          />
          <Paragraph>
            Check{' '}
            <Link href="http://hudl.com/design/writing/writing-for/teams/product/">
              UI label guidelines
            </Link>{' '}
            for more detail on case and punctuation.
          </Paragraph>

          <SectionMicroHelpText imgNameError="checkbox-errorhelptext" />
        </Section>

        <AccessibilityAuditComponentResultsSection componentName="Forms / Checkbox Group" />

        <SectionPlatform />
      </PageWithSubNav>
    );
  }
}
